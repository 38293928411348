<template>
  <div id="app">
    <div class="columns is-centered is-mobile nav-container" :class="{'scrolled':scrolled}">
      <div class="column is-10">
        <nav class="navbar" role="navigation" aria-label="main navigation">
          <div class="navbar-brand">
            <a href="https://genexinstitute.com/" class="is-flex">
              <img :src="logo" width="129" height="70" />
            </a>
          </div>

          <div id="navbarBasicExample" class="navbar-menu">
            <div class="navbar-end">
              <div class="navbar-item">
                <a class="navbar-text" href="https://genexinstitute.com/?page_id=126">CAMBRIDGE PDQ</a>
              </div>
              <div class="navbar-item">
                <a
                  class="navbar-text"
                  href="https://genexinstitute.com/?page_id=126"
                >GENEX INSTITUTE</a>
              </div>
            </div>
          </div>
        </nav>
      </div>
    </div>
    <router-view />
  </div>
</template>


<script>
export default {
  data() {
    return {
      scrolled: false
    };
  },
  computed: {
    logo() {
      if (this.scrolled == false) {
        return "https://generation-sis-assets.s3.eu-west-1.amazonaws.com/images/genex-logo-03.svg";
      } else {
        return "https://generation-sis-assets.s3.eu-west-1.amazonaws.com/images/genex-logo-03.svg";
      }
    }
  },

  // mounted() {
  //   window.addEventListener("scroll", this.handleScroll);
  // },
  // destroyed() {
  //   window.removeEventListener("scroll", this.handleScroll);
  // },
  methods: {
    handleScroll() {
      var scrollTop = window.pageYOffset;
      if (scrollTop >= 100) {
        if (this.scrolled == false) {
          this.scrolled = true;
        }
      } else {
        if (this.scrolled == true) {
          this.scrolled = false;
        }
      }
    }
  }
};
</script>
<style lang="scss">
@import url("https://fonts.googleapis.com/css?family=Open+Sans&display=swap");
$primary: #039be0;
@import "~bulma/sass/utilities/_all";

@import "~bulma";
@import "~buefy/src/scss/buefy";
#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  .nav-container {
    &.scrolled {
      margin-bottom: 0;
      background-color: white;
      box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.5);
      .navbar {
        height: 80px;
      }
      .navbar-text {
        color: rgb(2, 110, 159);
        &:hover {
          border-bottom: 2px solid #03a9f4;
          color: #03a9f4;
        }
      }
    }
    transition-property: all;
    transition-duration: 300ms;
    position: sticky;
    top: 0;
    z-index: 20;
    background-color: #039be0;
    .navbar {
      background-color: transparent;

      padding-top: 5px;
      padding-right: 15px;
      padding-bottom: 5px;
      padding-left: 15px;
      background-image: none;
      height: 100px;
    }
    .navbar-text {
      &:hover {
        border-bottom: 2px solid white;
      }
      font-family: "Open Sans", sans-serif;
      font-weight: 600;
      text-transform: uppercase;
      font-size: 14px;
      line-height: 1.5em;
      letter-spacing: 0px;
      color: #ffffff;
    }
  }
}
</style>
