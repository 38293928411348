<template>
  <div>
    <div id="smart-button-container">
      <div>
        <p>Course: {{pdq_course_name}}</p>
        <p>Cost: {{pdq_course_cost_int}} GBP</p>
      </div>
      <div v-show="false" style="text-align: center"><label for="description">Course </label><input type="text"   name="descriptionInput"
                                                                                     id="description" maxlength="127"
                                                                                     ></div>
      <p v-show="false" id="descriptionError" style="visibility: hidden; color:red; text-align: center;">Please enter a description</p>
      <div v-show="false"  style="text-align: center"><label for="amount">Cost </label><input name="amountInput" type="number"
                                                                              id="amount" ><span> GBP</span>
      </div>
      <p v-show="false" id="priceLabelError" style="visibility: hidden; color:red; text-align: center;">Please enter a price</p>
      <div v-show="false" id="invoiceidDiv" style="text-align: center; display: none;"><label for="invoiceid"> </label><input
         v-show="false" name="invoiceid" maxlength="127" type="text" id="invoiceid" value=""></div>
      <p v-show="false" id="invoiceidError" style="visibility: hidden; color:red; text-align: center;">Please enter an Invoice ID</p>
      <div style="text-align: center; margin-top: 0.625rem;" id="paypal-button-container"></div>
    </div>

  </div>
</template>


<script>
// import axios from 'axios'

export default {
  name: "Paypal",
  props: ["user_id", "pdq_course_id", "pdq_course_date_id", "pdq_course_cost","pdq_course_name","pdq_signup_id"],
  data() {
    return {
      error: "",
      hideForm: false,
      paymentDisabled: true,
      loading: false,
      clientSecret: null,
      paymentIntentJson: null,
      orderData: {
        user_id: this.user_id,
        pdq_course_id: this.pdq_course_id,
        pdq_course_date_id: this.pdq_course_date_id,

        currency: "gbp"
      },
      card: null
    };
  },
  methods: {},
  computed:{
    pdq_course_cost_int(){
      return parseInt(this.pdq_course_cost)
    }, pdq_signup_id_int(){
      return parseInt(this.pdq_signup_id)
    },
    name(){
      return this.pdq_course_name
    }
  },
  mounted() {
    let name = this.pdq_course_name
    let cost = this.pdq_course_cost
    let id = this.pdq_signup_id_int
    let router = this.$router
    this.$loadScript('https://www.paypal.com/sdk/js?client-id=ARA-HQYnumZ23n0eW7VXuT_IlnwVB4MGleNzxDZB7ue11epJ9bIInA76ywaHbT78dI0TGPckAed41Pwv&currency=GBP').then(()=>{  function initPayPalButton() {
      var description = document.querySelector('#smart-button-container #description');
      var amount = document.querySelector('#smart-button-container #amount');
      var descriptionError = document.querySelector('#smart-button-container #descriptionError');
      var priceError = document.querySelector('#smart-button-container #priceLabelError');
      var invoiceid = document.querySelector('#smart-button-container #invoiceid');
      var invoiceidError = document.querySelector('#smart-button-container #invoiceidError');
      var invoiceidDiv = document.querySelector('#smart-button-container #invoiceidDiv');
      description.value = name
      amount.value = cost
      invoiceid.value = id
      var elArr = [description, amount];

        invoiceidDiv.style.display = "block";


      var purchase_units = [];
      purchase_units[0] = {};
      purchase_units[0].amount = {};

      // function validate() {
      //   return true
      // }

      window.paypal.Buttons({
        style: {
          color: 'gold',
          shape: 'rect',
          label: 'paypal',
          layout: 'vertical',

        },

        onInit: function (data, actions) {
          actions.disable();

          if(invoiceidDiv.style.display === "block") {
            elArr.push(invoiceid);
          }

          elArr.forEach(function () {
            actions.enable();

            // eslint-disable-next-line no-unused-vars
            // item.addEventListener('keyup', function (event) {
            //   var result = elArr.every(validate);
            //   if (result) {
            //     actions.enable();
            //   } else {
            //     actions.disable();
            //   }
            // });
          });
        },

        onClick: function () {
          if (description.value.length < 1) {
            descriptionError.style.visibility = "visible";
          } else {
            descriptionError.style.visibility = "hidden";
          }

          if (amount.value.length < 1) {
            priceError.style.visibility = "visible";
          } else {
            priceError.style.visibility = "hidden";
          }

          if (invoiceid.value.length < 1 && invoiceidDiv.style.display === "block") {
            invoiceidError.style.visibility = "visible";
          } else {
            invoiceidError.style.visibility = "hidden";
          }

          purchase_units[0].description = description.value;
          purchase_units[0].amount.value = amount.value;

          if(invoiceid.value !== '') {
            purchase_units[0].invoice_id = invoiceid.value;
          }
        },

        createOrder: function (data, actions) {
          return actions.order.create({
            purchase_units: purchase_units,
          });
        },

        onApprove: function (data, actions) {
          return actions.order.capture().then(function () {
            this.$buefy.dialog.alert('Transaction Completed')
            setTimeout(() => {
              router.push(`/password`);
            }, 1000);

          });

        },

        onError: function (err) {
          console.log(err);
        }
      }).render('#paypal-button-container');
    }
      initPayPalButton();
    })


  }
};
</script>
