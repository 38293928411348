import Vue from 'vue'
import App from './App.vue'
import router from './router'
import Buefy from 'buefy'
import VueTelInput from 'vue-tel-input'
import Vuex from 'vuex'
import LoadScript from 'vue-plugin-load-script';

Vue.use(LoadScript);
Vue.use(Vuex)

Vue.use(VueTelInput)
Vue.use(Buefy)
Vue.config.productionTip = false

const store = new Vuex.Store({
  state: {
    user_id: null,
    pdq_course_id: null,
    pdq_course_date_id:null,
    pdq_course_cost:null,
    pdq_course_name:null,
    payment_type:null,
    pdq_signup_id:null
  },
  mutations: {
    SET_ID(state, id) {
      state.user_id = id
    },
    SET_COURSE_ID(state, id) {
      state.pdq_course_id = id
    },SET_COURSE_NAME(state, name) {
      state.pdq_course_name = name
    }, SET_COURSE_DATE_ID(state, id) {
      state.pdq_course_date_id = id
    },SET_COURSE_COST(state, cost) {
      state.pdq_course_cost = cost
    },SET_PAYMENT_TYPE(state, type) {
      state.payment_type = type
    },
    SET_SIGNUP_ID(state,id){
      state.pdq_signup_id =id
    }
  },
  actions: {
    set_id({
      commit
    }, payload) {
      commit('SET_ID', payload.id)
    },
    set_course_id({
      commit
    }, payload) {
      commit('SET_COURSE_ID', payload.pdq_course_id)
      commit('SET_COURSE_DATE_ID', payload.pdq_course_date_id)
      commit('SET_COURSE_COST', payload.pdq_course_cost)
      commit('SET_PAYMENT_TYPE', payload.payment_type)
      commit('SET_COURSE_NAME', payload.pdq_course_name)
      commit('SET_SIGNUP_ID', payload.pdq_signup_id)
    },

  }
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')