<template>
  <div class="section form-section">
    <div class="container">
      <b-tabs type="is-toggle-rounded" position="is-centered" v-model="currentTab">
        <b-tab-item label="Registration Type">
          <form @submit.prevent="changeTab(1)">
            <div class="columns box is-centered">
              <div class="column is-11">
                <b-field position="is-centered" label="Are you registering as an individual or a group?">
                  <b-select placeholder="Registration type" expanded required v-model="form.form_data.type">
                    <option value="individual">Individual</option>
                    <option value="group">Group</option>
                  </b-select>
                </b-field>
                <b-button type="is-primary" native-type="submit">
                  Next
                </b-button>
              </div>
            </div>
          </form>
        </b-tab-item>
        <b-tab-item v-if="form.form_data.type==='group'&&highestTab>0" label="Prior Experience">
          <form @submit.prevent="changeTab(2)">
            <div class="columns box is-centered">
              <div class="column is-11">
                <div class="columns is-centered is-multiline">
                  <div class="column is-6">
                    <b-field label="Date of application">
                      <b-input :value="formatDate(form.form_data.application_date)" disabled></b-input>
                    </b-field>
                  </div>
                  <div class="column is-6">
                    <b-field position="is-centered" label="Has any of the candidates completed a GenEx course before?">
                      <b-select placeholder="Prior Experience" expanded required
                                v-model="form.form_data.prior_experience">
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                      </b-select>
                    </b-field>
                  </div>
                </div>

                <b-button type="is-primary" native-type="submit">
                  Next
                </b-button>
              </div>
            </div>
          </form>
        </b-tab-item>
        <b-tab-item v-if="form.form_data.type==='individual'&&highestTab>0" label="Prior Experience">
          <form @submit.prevent="changeTab(2)">
            <div class="columns box is-centered">
              <div class="column is-11">
                <div class="columns is-centered is-multiline">
                  <div class="column is-6">
                    <b-field label="Date of application">
                      <b-input :value="formatDate(form.form_data.application_date)" disabled></b-input>
                    </b-field>
                  </div>
                  <div class="column is-6">
                    <b-field position="is-centered" label="Have you completed a GenEx course before? ">
                      <b-select placeholder="Prior Experience" expanded required
                                v-model="form.form_data.prior_experience">
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                      </b-select>
                    </b-field>
                  </div>
                </div>

                <b-button type="is-primary" native-type="submit">
                  Next
                </b-button>
              </div>
            </div>
          </form>
        </b-tab-item>
        <b-tab-item v-if="form.form_data.type==='group'&&highestTab>1" label="Course">
          <form @submit.prevent="checkTabForCustom()">
            <div class="columns box is-centered">
              <div class="column is-11">

                <b-field expanded position="is-centered" label="Pick a course">
                  <b-select placeholder="Course" expanded required v-model="selected_course">
                    <option v-for="course in courses" :key="course.id" :value="course">{{ course.name }} - Cost: £{{ course.cost }}
                    </option>
                  </b-select>
                </b-field>

                <b-field v-if="selected_course !==null && form.form_data.custom_date!==true" expanded
                         position="is-centered" label="Course date">
                  <b-select placeholder="Course date" expanded required v-model="selected_course_date">
                    <option v-for="date in selected_course.dates" :key="date.id" :value="date">{{ date.course_date }}
                    </option>
                  </b-select>
                </b-field>
                <b-field label="Custom Date">
                  <b-checkbox v-model="form.form_data.custom_date" :true-value="true" :false-value="false">Please note,
                    if you would like to choose this option, you are required to enrol more than 10 candidates and have
                    in-house mentors to assist and support these candidates. Your in-house mentors will be required to
                    complete a GenEx certified course aligned to Cambridge specifications two weeks prior to the start
                    of the course.
                  </b-checkbox>
                </b-field>
                <b-field v-if="form.form_data.custom_date">
                  <b-datepicker
                      position="is-top-right"
                      v-model="form.form_data.course_date"
                      type="month"
                      placeholder="Click to select..."
                      icon="calendar-today"
                      trap-focus>
                  </b-datepicker>
                </b-field>
                <b-button type="is-primary" native-type="submit">
                  Next
                </b-button>
              </div>
            </div>
          </form>
        </b-tab-item>
        <b-tab-item v-if="form.form_data.type==='individual'&&highestTab>1" label="Course">
          <form @submit.prevent="changeTab(3)">
            <div class="columns box is-centered">
              <div class="column is-11">

                <b-field expanded position="is-centered" label="Pick a course">
                  <b-select placeholder="Course" expanded required v-model="selected_course">
                    <option v-for="course in courses" :key="course.id" :value="course">{{ course.name }} - Cost: £{{ course.cost }}
                    </option>
                  </b-select>
                </b-field>

                <b-field v-if="selected_course !==null" expanded position="is-centered" label="Course date">
                  <b-select placeholder="Course date" expanded required v-model="selected_course_date">
                    <option v-for="date in selected_course.dates" :key="date.id" :value="date">{{ date.course_date }}
                    </option>
                  </b-select>
                </b-field>
                <b-button type="is-primary" native-type="submit">
                  Next
                </b-button>
              </div>
            </div>
          </form>
        </b-tab-item>
        <b-tab-item v-if="form.form_data.type==='group'&&highestTab>2" label="Cohort Details">
          <form @submit.prevent="changeTab(4)">
            <div class="columns box is-centered">
              <div class="column is-11">
                <div class="columns  is-multiline">
                  <div class="column is-12">
                    <b-field label="Number of Candidates">
                      <b-numberinput required v-model="form.form_data.number_of_candidates"
                                     :min="form.form_data.custom_date===false?1:11"></b-numberinput>

                    </b-field>
                  </div>
                  <div class="column">
                    <b-field>
                      <div class="table-container">
                        <table style="width: 100%" class="table is-bordered is-striped">
                          <thead>
                          <tr class="table-input">
                            <th>Candidate first name:</th>
                            <th>Candidate surname:</th>
                            <th>Candidate email address:</th>

                          </tr>
                          </thead>
                          <tbody>
                          <!--suppress JSUnusedLocalSymbols -->
                          <tr v-for="(candidate,index) in acceptedCandidates" :key="index">
                            <td>
                              <b-field>
                                <b-input
                                    required
                                    autocomplete="off"
                                    placeholder="First name"
                                    v-model="form.form_data.cohort[index].first_name"
                                />
                              </b-field>
                            </td>

                            <td>
                              <b-field>
                                <b-input
                                    required
                                    autocomplete="off"
                                    placeholder="Last name"
                                    v-model="form.form_data.cohort[index].last_name"
                                />
                              </b-field>
                            </td>
                            <td>
                              <b-field>
                                <b-input
                                    type="email"
                                    required
                                    autocomplete="off"
                                    placeholder="Email"
                                    v-model="form.form_data.cohort[index].email_address"
                                />
                              </b-field>
                            </td>

                          </tr>
                          </tbody>
                        </table>

                      </div>
                    </b-field>
                  </div>

                </div>


                <b-button type="is-primary" native-type="submit">
                  Next
                </b-button>
              </div>
            </div>
          </form>
        </b-tab-item>
        <b-tab-item v-if="form.form_data.type==='group'&&highestTab>3" label="Your Details">
          <form @submit.prevent="changeTab(5)">
            <div class="columns box is-centered">
              <div class="column is-11">
                <div class="columns is-centered is-multiline">
                  <div class="column is-6">
                    <b-field label="Institution of employment">
                      <b-input required v-model="form.form_data.institution_of_employment"></b-input>

                    </b-field>
                  </div>
                  <div class="column is-6">
                    <b-field label="First name">
                      <b-input required v-model="form.first_name"></b-input>

                    </b-field>
                  </div>
                  <div class="column is-6">
                    <b-field label="Surname">
                      <b-input required v-model="form.last_name"></b-input>
                    </b-field>
                  </div>

                  <div class="column is-6">
                    <b-field label="Email address">
                      <b-input type="email" required v-model="form.email"></b-input>
                    </b-field>
                  </div>
                  <div class="column is-6">
                    <b-field label="Contact number">
                      <vue-tel-input
                          required
                          :inputOptions="{showDialCode:true,tabindex:1}"
                          :preferredCountries="['GB','US']"
                          v-model="form.form_data.number"
                          :class="'input'"
                      ></vue-tel-input>
                    </b-field>
                  </div>
                  <div class="column is-6">
                    <b-field label="Role at the institution of employment">
                      <b-input required v-model="form.form_data.role_at_institution"></b-input>
                    </b-field>
                  </div>
                  <div class="column is-6">
                    <b-field label="Date of birth">
                      <b-datepicker position="is-top-right"
                                    required v-model="form.form_data.date_of_birth">

                      </b-datepicker>
                    </b-field>
                  </div>
                </div>


                <b-button type="is-primary" native-type="submit">
                  Next
                </b-button>
              </div>
            </div>
          </form>
        </b-tab-item>
        <b-tab-item v-if="form.form_data.type==='group'&&highestTab>4" label="Mentor Details">
          <form @submit.prevent="changeTab(6)">
            <div class="columns box is-centered">
              <div class="column is-11">
                <div class="columns  is-multiline">
                  <div class="column is-12">
                    <b-field label="Number of Mentors">
                      <b-numberinput required v-model="form.form_data.number_of_mentors"
                      ></b-numberinput>

                    </b-field>
                  </div>
                  <div class="column">
                    <b-field>
                      <div class="table-container">
                        <table style="width: 100%" class="table is-bordered is-striped">
                          <thead>
                          <tr class="table-input">
                            <th>Mentor first name:</th>
                            <th>Mentor surname:</th>
                            <th>Mentor email address:</th>

                          </tr>
                          </thead>
                          <tbody>
                          <!--suppress JSUnusedLocalSymbols -->
                          <tr v-for="(mentor,index) in acceptedMentors" :key="index">
                            <td>
                              <b-field>
                                <b-input
                                    required
                                    autocomplete="off"
                                    placeholder="First name"
                                    v-model="form.form_data.mentors[index].mentor_first_name"
                                />
                              </b-field>
                            </td>

                            <td>
                              <b-field>
                                <b-input
                                    required
                                    autocomplete="off"
                                    placeholder="Last name"
                                    v-model="form.form_data.mentors[index].mentor_last_name"
                                />
                              </b-field>
                            </td>
                            <td>
                              <b-field>
                                <b-input
                                    type="email"
                                    required
                                    autocomplete="off"
                                    placeholder="Email"
                                    v-model="form.form_data.mentors[index].mentor_email_address"
                                />
                              </b-field>
                            </td>

                          </tr>
                          </tbody>
                        </table>

                      </div>
                    </b-field>
                  </div>

                </div>


                <b-button type="is-primary" native-type="submit">
                  Next
                </b-button>
              </div>
            </div>
          </form>
        </b-tab-item>

        <b-tab-item v-if="form.form_data.type==='individual'&&highestTab>2" label="Candidate Details">
          <form @submit.prevent="changeTab(4)">
            <div class="columns box is-centered">
              <div class="column is-11">
                <div class="columns is-centered is-multiline">
                  <div class="column is-6">
                    <b-field label="First name">
                      <b-input required v-model="form.first_name"></b-input>

                    </b-field>
                  </div>
                  <div class="column is-6">
                    <b-field label="Surname">
                      <b-input required v-model="form.last_name"></b-input>
                    </b-field>
                  </div>
                  <div class="column is-6">
                    <b-field label="Gender">
                      <b-input required v-model="form.form_data.gender"></b-input>
                    </b-field>
                  </div>
                  <div class="column is-6">
                    <b-field label="First language">
                      <b-input required v-model="form.form_data.language"></b-input>
                    </b-field>
                  </div>
                  <div class="column is-6">
                    <b-field expanded label="Please rate your proficiency in english">

                      <b-select expanded
                                @input="confirmEnglish()"
                                v-model="form.form_data.english_proficiency"
                                class="has-text-centered"
                                placeholder="Select level"
                      >
                        <option
                            v-for="proficiency in proficiencies"
                            :value="proficiency"
                            :key="proficiency"
                        >{{ proficiency }}
                        </option>
                      </b-select>

                    </b-field>
                  </div>
                  <div class="column is-6">
                    <b-field label="Country of residence">
                      <b-input required v-model="form.form_data.country_of_residence"></b-input>
                    </b-field>
                  </div>
                  <div class="column is-6">
                    <b-field label="Email address">
                      <b-input type="email" required v-model="form.email"></b-input>
                    </b-field>
                  </div>
                  <div class="column is-6">
                    <b-field label="Contact number">
                      <vue-tel-input
                          required
                          :inputOptions="{showDialCode:true,tabindex:1}"
                          :preferredCountries="['GB','US']"
                          v-model="form.form_data.number"
                          :class="'input'"
                      ></vue-tel-input>
                    </b-field>
                  </div>
                  <div class="column is-6">
                    <b-field label="Identity number">
                      <b-input required v-model="form.form_data.identity_number"></b-input>
                    </b-field>
                  </div>
                  <div class="column is-6">
                    <b-field label="Date of birth">
                      <b-datepicker position="is-top-right"
                                    required v-model="form.form_data.date_of_birth">

                      </b-datepicker>
                    </b-field>
                  </div>
                </div>


                <b-button type="is-primary" native-type="submit">
                  Next
                </b-button>
              </div>
            </div>
          </form>
        </b-tab-item>
        <b-tab-item v-if="form.form_data.type==='individual'&&highestTab>3" label="Current Employment">
          <form @submit.prevent="changeTab(5)">
            <div class="columns box is-centered">
              <div class="column is-11">
                <div class="columns is-centered is-multiline">
                  <div class="column is-6">
                    <b-field label="Institution of Employment">
                      <b-input required v-model="form.form_data.institution_of_employment"></b-input>

                    </b-field>
                  </div>
                  <div class="column is-6">
                    <b-field label="What Grade(s) are you currently teaching?">

                      <b-input required type="textarea" v-model="form.form_data.grades"></b-input>

                    </b-field>
                  </div>
                  <div class="column is-6">
                    <b-field label="What Subject(s) are you currently teaching?">

                      <b-input required type="textarea" v-model="form.form_data.subjects"></b-input>

                    </b-field>
                  </div>
                  <div class="column is-6">
                    <b-field
                        label="How many years experience do you have teaching the Cambridge curriculum?"
                    >

                      <b-select
                          required
                          v-model="form.form_data.years_experience"
                          class="has-text-centered"
                          placeholder="Please select"
                      >
                        <option v-for="year in experience" :value="year" :key="year">{{ year }}</option>
                      </b-select>
                    </b-field>
                  </div>
                  <div class="column is-6">
                    <b-field label="Line Manager">
                      <b-input v-model="form.form_data.line_manager"></b-input>

                    </b-field>
                  </div>
                  <div class="column is-6">
                    <b-field label="Line Manager's Email">

                      <b-input type="email" v-model="form.form_data.line_manager_email"></b-input>

                    </b-field>
                  </div>
                </div>

                <b-button type="is-primary" native-type="submit">
                  Next
                </b-button>
              </div>
            </div>
          </form>
        </b-tab-item>
        <b-tab-item v-if="form.form_data.type==='individual'&&highestTab>4" label="Mentor Details">
          <form @submit.prevent="changeTab(6)">
            <div class="columns box is-centered">
              <div class="column is-11">
                <div class="columns is-centered">
                  <div class="column is-6"><p class="has-text-centered">You can choose between a qualified online GenEx
                    Mentor being assigned to you or choosing an in-house Mentor from your educational institution. </p>
                    <p class="has-text-centered">Please note, if you choose your own in-house Mentor, they are required
                      to complete a GenEx certified course aligned to Cambridge specifications two weeks prior to the
                      start of the course. </p></div>
                </div>

                <div class="columns is-centered is-multiline">
                  <div class="column is-6">
                    <b-field expanded label="Mentor type">

                      <b-select expanded
                                required
                                v-model="form.form_data.mentor"
                                class="has-text-centered"
                                placeholder="Select mentor type"
                      >
                        <option value="Online GenEx Mentor">Online GenEx Mentor</option>
                        <option value="In-house Mentor">In-house Mentor</option>
                      </b-select>

                    </b-field>
                  </div>

                  <div v-if="form.form_data.mentor==='In-house Mentor'" class="column is-6">
                    <b-field label="Mentor first name">
                      <b-input required v-model="form.form_data.mentor_first_name"></b-input>

                    </b-field>
                  </div>
                  <div v-if="form.form_data.mentor==='In-house Mentor'" class="column is-6">
                    <b-field label="Mentor surname">
                      <b-input required v-model="form.form_data.mentor_last_name"></b-input>

                    </b-field>
                  </div>
                  <div v-if="form.form_data.mentor==='In-house Mentor'" class="column is-6">
                    <b-field label="Mentor email">
                      <b-input type="email" required v-model="form.form_data.mentor_email"></b-input>

                    </b-field>
                  </div>
                  <div v-if="form.form_data.mentor==='In-house Mentor'" class="column is-6">
                    <b-field
                        label="Please confirm that your Mentor has been informed of the requirements and has agreed to be your Mentor for the duration of this course.">
                      <b-select expanded required v-model="form.form_data.mentor_agreement">
                        <option value="yes">yes</option>
                        <option value="no">no</option>
                      </b-select>

                    </b-field>
                  </div>

                </div>

                <b-button type="is-primary" native-type="submit">
                  Next
                </b-button>
              </div>
            </div>
          </form>
        </b-tab-item>
        <b-tab-item v-if="form.form_data.type==='group'&&highestTab>5" label="Invoice Details & Submit">
          <form @submit.prevent.once="submitting=true;form.form_data.payment_type='invoice';submit('invoice')">
            <div class="columns box is-centered">
              <div class="column is-11">
                <div class="columns is-centered is-multiline">
                  <div class="column is-6">
                    <b-field label="Name/organisation to be invoiced">
                      <b-input required v-model="form.form_data.name_to_be_invoiced"></b-input>

                    </b-field>
                  </div>
                  <div class="column is-6">
                    <b-field label="Contact number">
                      <vue-tel-input
                          required

                          :inputOptions="{showDialCode:true,tabindex:1}"
                          :preferredCountries="['GB','US']"
                          v-model="form.form_data.contact_number"
                          :class="'input'"
                      ></vue-tel-input>
                    </b-field>
                  </div>
                  <div class="column is-6">
                    <b-field label="Contact email address">
                      <b-input v-model="form.form_data.contact_email" required type="email"></b-input>
                    </b-field>
                  </div>
                  <div class="column is-6">
                    <b-field label="Physical address">
                      <b-input v-model="form.form_data.physical_address" required></b-input>
                    </b-field>
                  </div>
                  <div class="column is-6">
                    <b-field expanded label="Indicate whether you would like more information about a payment plan">

                      <b-select expanded
                                required
                                v-model="form.form_data.payment_plan"
                                class="has-text-centered"
                                placeholder="Select Mentor type"
                      >
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                      </b-select>

                    </b-field>
                  </div>

                </div>

                <b-button :disabled="submitting" type="is-primary" native-type="submit">
                  Submit
                </b-button>
              </div>
            </div>
          </form>
        </b-tab-item>
        <b-tab-item v-if="form.form_data.type==='individual'&&highestTab>5" label="Requirements and Confirmation">
          <form @submit.prevent="changeTab(7)">
            <div class="columns box is-centered">
              <div class="column is-11">
                <div class="columns is-centered">
                  <div class="column is-6 has-text-left content"><p>To be able to complete this course, you need to meet
                    the following
                    requirements:</p>
                    <ul>
                      <li>Have regular access to the Internet and an electronic device.</li>
                      <li>Have basic computer literacy (ability to type, edit, upload, download, search the Internet,
                        etc.)
                      </li>
                      <li>Be willing to adhere to the timeline and task submissions set out for the course.</li>
                      <li>Be a full-time or part-time teacher employed in an educational institution, such as a school,
                        college, university, adult training centre, etc.
                      </li>
                      <li>Teach in a current school over a full academic year, for a minimum of 24 weeks and a minimum
                        of 6 hours per week.
                      </li>
                      <li>Teach a group with a minimum of 6 learners.</li>
                      <li>Be responsible for planning, teaching and formatively assessing a group of learners.</li>
                    </ul>
                    <p>You can can download the full Requirements and Expectations document here: <b-button type="is-link" tag="a" download target="_blank" href="https://generation-sis-assets.s3-eu-west-1.amazonaws.com/downloads/Requirements_and_Expectations_6208_Jan-2021.pdf">Download</b-button></p>
                    <div>
                      <b-field>
                        <b-checkbox v-model="form.form_data.agree_to_requirements">I understand the requirements and
                          expectations and that, to the best of my knowledge, I
                          am able to meet these over the course of this programme. I also understand that if I am unable
                          to
                          meet any of the requirements and/or expectations at any point during the course, it may not be
                          possible for me to continue with the online course. A refund of the course fees will also not
                          be
                          possible.
                        </b-checkbox>
                      </b-field>
                    </div>
                  </div>

                </div>


                <b-button :disabled="!form.form_data.agree_to_requirements" type="is-primary" native-type="submit">
                  Submit and continue to the payment portal
                </b-button>
              </div>
            </div>
          </form>
        </b-tab-item>
        <b-tab-item v-if="form.form_data.type==='individual'&&highestTab>5" label="Payment">
          <div class="columns box is-centered">
            <div class="column is-11">
              <div class="columns is-centered">
                <div class="column has-text-centered">
                  <p class="has-text-centered">If you choose to pay now via Paypal or credit/debit card you will be redirected to create your PDQ account password after paying.</p>
                  <b-field position="is-centered" grouped>
                    <b-field position="is-centered">
                      <b-button :disabled="submitting" @click.once="submitting=true;form.form_data.payment_type='stripe';submit('stripe')">Pay with credit/debit
                        card
                      </b-button>
                    </b-field>
                    <b-field position="is-centered">
                      <b-button :disabled="submitting" @click.once="submitting=true;form.form_data.payment_type='paypal';submit('paypal')">Pay with Paypal
                      </b-button>
                    </b-field>
                    <b-field position="is-centered">
                      <b-button :disabled="submitting" @click.once="submitting=true;form.form_data.payment_type='invoice';submit('invoice')">Request Invoice
                      </b-button>
                    </b-field>
                  </b-field>
                </div>

              </div>


            </div>
          </div>
        </b-tab-item>
      </b-tabs>
    </div>
    <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>
  </div>
</template>
<script>
import axios from "axios";

import {API_URL} from "@/config.js";


export default {
  name: "home",
  data() {
    return {
      currentTab: 0,
      highestTab: 0,
      isLoading: false,
      t_and_a: false,
      errors: null,
      submitting:false,
      validationErrors: null,
      selected_course: null,
      selected_course_date: null,
      form: {
        form_data: {
          application_date: new Date(),
          course: null,
          gender: null,
          course_date: null,
          number: "",
          number_of_candidates: 1,
          number_of_mentors: 1,
          cohort: [{first_name: '', last_name: '', email_address: ''}],
          mentors: [{mentor_first_name: '', mentor_last_name: '', mentor_email_address: ''}],
          identity_number: null,
          institution_of_employment: "",
          role_at_institution: '',
          line_manager: "",
          line_manager_email: "",
          line_manager_number: "",
          mentor: null,
          grades: "",
          subjects: "",
          custom_date: false,
          years_experience: null,
          prior_experience: null,
          internet_access: "No",
          device: "No",
          language: "",
          agree_to_requirements: false,
          english_proficiency: null,
          payment_type: null,
          first_name: "",
          last_name: "",
          email: "",
          type: null,
          country_of_residence: null,
          date_of_birth: null,
          payment_plan: null,
          physical_address: null,
          contact_email: null,
          contact_number: null,
          name_to_be_invoiced: null
        },
        first_name: "",
        last_name: "",
        email: "",
        type: null
      },
      experience: ["less than 1", "1-3 years", "4-5 years", "6+ years"],
      proficiencies: ["Excellent", "Good", "Average", "Poor"],
      courses: [],
    };
  },
  computed: {
    acceptedCandidates() {
      return this.form.form_data.cohort.slice(0, this.number_of_candidates)
    },
    acceptedMentors() {
      return this.form.form_data.mentors.slice(0, this.number_of_mentors)
    },
    number_of_candidates() {
      return this.form.form_data.number_of_candidates
    }, number_of_mentors() {
      return this.form.form_data.number_of_mentors
    }
  },
  watch: {
    number_of_candidates(newVal) {

      if (newVal > this.form.form_data.cohort.length) {
        let x = newVal - this.form.form_data.cohort.length
        for (let i = 0; i < x; i++) {
          this.form.form_data.cohort.push({first_name: '', last_name: '', email_address: ''})
        }
      }
    }, number_of_mentors(newVal) {

      if (newVal > this.form.form_data.mentors.length) {
        let x = newVal - this.form.form_data.mentors.length
        for (let i = 0; i < x; i++) {
          this.form.form_data.mentors.push({mentor_first_name: '', mentor_last_name: '', mentor_email_address: ''})
        }
      }
    }
  },
  mounted() {
    this.isLoading = true
    axios({
      method: "get",
      headers: {"Content-Type": "application/json"},
      url: `${API_URL}/genex/pdq-courses`,
    })
        .then(result => {
          this.courses = result.data
          this.isLoading = false;


        })
        .catch(err => {
          this.isLoading = false;
          console.log(err);
        });
    this.isLoading = false
  },
  methods: {
    checkTabForCustom() {
      if (this.form.form_data.custom_date !== false && this.form.form_data.course_date == null) {
        return this.$buefy.toast.open('Please select a date!')
      }
      if (this.form.form_data.custom_date !== false) {
        this.form.form_data.number_of_candidates = 11
      }
      this.changeTab(3)
    },
    changeTab(target) {
      if (this.highestTab < target) {
        this.highestTab = target
      }
      this.$nextTick(() => {
        this.currentTab = target

      })
    },
    resetApplication() {
      this.form = {
        form_data: {
          application_date: new Date(),
          course: null,
          gender: null,
          course_date: null,
          message: "",
          number: "",
          number_of_candidates: 1,
          number_of_mentors: 1,
          cohort: [{first_name: '', last_name: '', email_address: ''}],
          mentors: [{mentor_first_name: '', mentor_last_name: '', mentor_email_address: ''}],
          identity_number: null,
          institution_of_employment: "",
          role_at_institution: '',
          line_manager: "",
          line_manager_email: "",
          line_manager_number: "",
          mentor: null,
          grades: "",
          subjects: "",
          custom_date: false,
          years_experience: null,
          prior_experience: null,
          internet_access: "No",
          device: "No",
          language: "",
          agree_to_requirements: false,
          english_proficiency: null,
          payment_type: null,
          first_name: "",
          last_name: "",
          email: "",
          type: null,
          country_of_residence: null,
          date_of_birth: null,
          payment_plan: null,
          physical_address: null,
          contact_email: null,
          contact_number: null,
          name_to_be_invoiced: null
        },
        first_name: "",
        last_name: "",
        email: "",
        type: null
      }
      this.selected_course = null
      this.selected_course_date =null
      this.submitting = false
      this.changeTab(0)
      this.highestTab = 0
    },
    confirmEnglish() {
      this.$buefy.dialog.confirm({
        message: `<p>Please note, to take part in the Cambridge International Certificate and/or Diploma in Teaching and Learning (6208/6209), candidates are required to have sufficient competence in English. All candidates should have English language competence comparable to Level B2 in the Common European Framework of Reference for Language (CEFR). We recommend a minimum requirement of 5.5 on the International English Language Testing System.</p>
<p>Please confirm that you understand and would like to continue.</p>`,
        confirmText: 'Yes',
        cancelText: 'No',
        canCancel: ['button'],
        onConfirm: () => {
        },
        onCancel: () => {
          this.resetApplication()
        }
      })
    },
    formatDate(date) {
      return date.toDateString();
    },
    submit(paymentType = 'invoice') {
      this.isLoading = true;
      this.errors = null;
      if (this.form.form_data.type === 'group') {
        this.form.form_data.cohort = this.acceptedCandidates
        this.form.form_data.mentors = this.acceptedMentors
      }
      this.form.form_data.course = this.selected_course.name;
      this.form.form_data.cost = this.selected_course.cost;
      if (this.form.form_data.type !== 'group') {
        this.form.form_data.course_date = this.selected_course_date.date;
      }
      this.form.form_data.first_name = this.form.first_name;
      this.form.form_data.last_name = this.form.last_name;
      this.form.form_data.email = this.form.email;
      this.form.type = this.form.form_data.type
      let data = new FormData();
      // data.append('form_data', JSON.stringify(this.form.form_data))
      // data.append('first_name', JSON.stringify(this.form.first_name))
      // data.append('last_name', JSON.stringify(this.form.last_name))
      // data.append('email', JSON.stringify(this.form.email))
      // data.append('type', JSON.stringify(this.form.type))
      for (const key in this.form) {
        let value = this.form[key];
        if (typeof value === "object") {
          value = JSON.stringify(value);
        }
        data.append(key, value);
      }

      axios({
        method: "post",
        headers: {"Content-Type": "application/json"},
        url: `${API_URL}/genex/register`,
        data: data
      })
          .then(result => {
            if (paymentType === 'invoice') {
              this.$buefy.dialog.alert({
                title: 'Registration Sent',
                message: 'Thank you for applying, a member of our team will be in contact with you shortly!',
                confirmText: 'Ok',
                onConfirm: this.resetApplication()
              })
            } else {
              this.$store.dispatch("set_id", {id: result.data.user_id});
              console.log(this.selected_course)
              this.$store.dispatch("set_course_id", {
                pdq_course_id: this.selected_course.id,
                pdq_course_date_id: this.selected_course_date.id,
                payment_type: this.form.form_data.payment_type,
                pdq_course_cost: this.selected_course.cost,
                pdq_course_name: this.selected_course.name,
                pdq_signup_id: result.data.pdq_signup_id
              }).then(() => {
                if (result.data.errors) {
                  this.isLoading = false;
                  this.errors = result.data.errors;
                } else {
                  this.$router.push(`/payment/`);
                }
              })


            }
          })
          .catch(err => {
            this.isLoading = false;
            console.log(err);
          });

    }
  }
};
</script>

<style scoped lang="scss">
.form-section {
  background-color: #f5fafd;
}
</style>