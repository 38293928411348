<template>
  <div class="section">
    <div class="container">
      <div class="columns is-centered">
        <div class="column">
          <p>Please enter a password below, it must be at least 8 characters long. Once you have done so you will be directed to the portal page where you may log in with your new account.</p>
          <ValidationObserver ref="observer">
            <b-field label="Password">
              <ValidationProvider rules="required|min|password:@confirm" v-slot="{ errors }">
                <b-input type="password" v-model="password" name="password" />
                <span>{{ errors[0] }}</span>
              </ValidationProvider>
            </b-field>

            <b-field label="Confirm password">
              <ValidationProvider rules="required" name="confirm" v-slot="{ errors }">
                <b-input type="password" v-model="confirmPassword" />
                <span>{{ errors[0] }}</span>
              </ValidationProvider>
            </b-field>
          </ValidationObserver>
          <div class="columns is-centered" style="margin-top:1rem;">
            <div class="column">
              <b-button @click.prevent="submit" expanded type="is-primary">Submit</b-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver, extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";
import { API_URL, GENEX_URL } from "@/config.js";

extend("required", {
  ...required,
  message: "This field is required"
});
extend("password", {
  params: ["target"],
  validate(value, { target }) {
    return value === target;
  },
  message: "Password confirmation does not match"
});
extend("min", value => {
  if (value.length >= 8) {
    return true;
  }
  return "The password must be at least 8 characters";
});
import axios from "axios";
export default {
  name: "password",
  components: { ValidationProvider, ValidationObserver },
  data() {
    return {
      password: null,
      confirmPassword: null
    };
  },
  methods: {
    submit() {
      this.$refs.observer.validate().then(valid => {
        if (!valid) {
          return;
        } else {
          axios({
            method: "post",
            headers: { "Content-Type": "application/json" },
            url: `${API_URL}/genex/password`,
            data: {
              user_id: this.$store.state.user_id,
              password: this.password
            }
          })
            .then(result => {
              console.log(result);
              window.location = GENEX_URL;
            })
            .catch(err => {
              console.log(err);
            });
        }
      });
    }
  }
};
</script>
