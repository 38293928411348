<template>
  <div>
    <div v-show="!hideForm" ref="payment_form" class="box">
      <div @change="log" ref="card" id="card-element">
        <!-- A Stripe Element will be inserted here. -->
      </div>
      <div class="has-text-danger">{{error}}</div>
    </div>
    <b-loading :active.sync="loading"></b-loading>

    <button
      @click="pay"
      :aria-disabled="paymentDisabled"
      v-show="!hideForm"
      class="button has-background-info has-text-white"
    >Submit Payment</button>
    <div v-show="hideForm">
      <p>Payment completed</p>
      <p>You'll now be redirected to the password page.</p>
      <!-- <pre>
        <code>
          {{paymentIntentJson}}
        </code>
      </pre>-->
    </div>
  </div>
</template>
<script src="https://js.stripe.com/v3/"></script> 

<script>
import axios from "axios";
import { API_URL } from "@/config.js";
var stripe;

export default {
  name: "StripeCard",
  props: ["user_id","pdq_course_id","pdq_course_date_id" ],
  data() {
    return {
      error: "",
      hideForm: false,
      paymentDisabled: true,
      loading: false,
      clientSecret: null,
      paymentIntentJson: null,
      orderData: {
        user_id: this.user_id,
        pdq_course_id:this.pdq_course_id,
        pdq_course_date_id:this.pdq_course_date_id,

        currency: "gbp"
      },
      card: null
    };
  },
  methods: {
    createIntent() {
      axios({
        method: "post",
        headers: { "Content-Type": "application/json" },
        url: `${API_URL}/payments/stripe-intent`,
        data: this.orderData
      })
        .then(result => this.setupElements(result.data))
        .then((this.paymentDisabled = false));
    },
    log() {
      console.log("log");
    },
    setupElements(data) {
      stripe = Stripe(data.publishableKey);
      var elements = stripe.elements();
      var style = {
        base: {
          color: "#32325d",
          fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
          fontSmoothing: "antialiased",
          fontSize: "16px",
          "::placeholder": {
            color: "#aab7c4"
          }
        },
        invalid: {
          color: "#fa755a",
          iconColor: "#fa755a"
        }
      };
      var card = elements.create("card", { style: style });
      this.card = card;
      card.mount(this.$refs.card);
      card.addEventListener("change", event => {
        if (event.error) {
          this.error = event.error.message;
        } else {
          this.error = "";
        }
      });
      this.clientSecret = data.clientSecret;
      return {
        stripe: stripe,
        card: card,
        clientSecret: data.clientSecret
      };
    },
    pay() {
      this.loading = true;
      // Initiate the payment.
      // If authentication is required, confirmCardPayment will automatically display a modal
      stripe
        .confirmCardPayment(this.clientSecret, {
          payment_method: {
            card: this.card
          }
        })
        .then(result => {
          if (result.error) {
            this.error = result.error.message;
            this.loading = false;
          } else {
            this.orderComplete();
          }
        });
    },
    orderComplete() {
      stripe.retrievePaymentIntent(this.clientSecret).then(result => {
        var paymentIntent = result.paymentIntent;
        this.paymentIntentJson = JSON.stringify(paymentIntent, null, 2);

        this.hideForm = true;

        this.loading = false;
        setTimeout(() => {
          this.$router.push(`/password`);
        }, 1000);
      });
    }
  },
  mounted() {
    this.createIntent();
  }
};
</script>
