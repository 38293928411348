<template>
  <div class="section">
    <div class="columns is-centered">
      <div class="column is-4">
        <p
            class="box"
        >Thank you for applying! Please complete the payment to finish your application. Once it has been successfully
          completed you will be redirected to create a password for your new account.</p>
        <StripeCard
            v-if="$store.state.payment_type==='stripe'"
            :user_id="$store.state.user_id"
            :pdq_course_id="$store.state.pdq_course_id"
            :pdq_course_date_id="$store.state.pdq_course_date_id"
            class="box has-background-light"
        />
        <Paypal
            v-if="$store.state.payment_type==='paypal'"
            :user_id="$store.state.user_id"
            :pdq_course_id="$store.state.pdq_course_id"
            :pdq_course_date_id="$store.state.pdq_course_date_id"
            :pdq_course_cost="$store.state.pdq_course_cost"
            :pdq_course_name="$store.state.pdq_course_name"
            :pdq_signup_id="$store.state.pdq_signup_id"
            class="box has-background-light"
        />
      </div>
    </div>
  </div>
</template>

<script>
import StripeCard from "@/components/StripeCard";
import Paypal from "@/components/Paypal";

export default {
  name: "payment",
  components: {
    Paypal,
    StripeCard
  }
};
</script>
